<template>
  <b-card-normal title="Ödeme Ayarları" :showLoading="show">
    <template v-slot:body>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="font-weight-bold h6">Kapıda Ödeme</label>
                <b-form-checkbox :checked="form.kapida_odeme" v-model="form.kapida_odeme" switch />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="font-weight-bold h6">Havale/EFT Ödeme</label>
                <b-form-checkbox :checked="form.havale_eft" v-model="form.havale_eft" switch />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="font-weight-bold h6">Kredi Kartı Ödeme</label>
                <b-form-checkbox :checked="form.kredi_karti" v-model="form.kredi_karti" switch />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="form.kredi_karti">
            <b-col cols="12" class="py-2 border-bottom">
              <h3>İyzico Bilgileri</h3>
            </b-col>
            <b-col cols="12" class="pt-1">
              <validation-provider name="Api Anahtarı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Api Anahtarı">
                  <b-form-input
                    class="rounded-0"
                    ref="iyzico_api"
                    v-model="form.iyzico_api"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Güvenlik Anahtarı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Güvenlik Anahtarı">
                  <b-form-input
                    class="rounded-0"
                    ref="iyzico_guvenlik"
                    v-model="form.iyzico_guvenlik"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Uri" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Uri">
                  <b-form-input
                    class="rounded-0"
                    ref="iyzico_uri"
                    v-model="form.iyzico_uri"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider name="Iyzicoya Bağlı Banka" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Iyzicoya Bağlı Banka" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                  <v-select
                    v-model="form.iyzico_banka"
                    :options="bankalar"
                    :reduce="(banka) => banka.banka_iban"
                    label="banka_adi"
                    :clearable="true"
                    class="invoice-filter-select d-block"
                    :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button squared block type="submit" variant="primary">
                <feather-icon icon="CheckSquareIcon" class="mr-50" />
                <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
    vSelect,
  },
  computed: {
    ...mapGetters(['getOdemeBankaBilgi']),
  },
  data: () => ({
    show: false,
    form: {
      _id: null,
      kapida_odeme: false,
      havale_eft: false,
      kredi_karti: false,
      iyzico_api: null,
      iyzico_guvenlik: null,
      iyzico_uri: null,
      iyzico_banka: null,
    },
    bankalar: [],
  }),
  created() {
    this.handlerOdemeConfig();
  },
  methods: {
    async handlerOdemeConfig() {
      this.show = true;
      await this.$store.dispatch('odemeAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          this.form = res.data.data;
        }
        this.show = false;
      });
      await this.$store.dispatch('odemeBankaBilgiListele');
      this.bankalar = this.getOdemeBankaBilgi;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.show = true;
      this.$store
        .dispatch('odemeAyarlariEkle', this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
